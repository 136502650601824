<template>
  <CModal :show.sync="mutatedShow"
          :title="$t('ManualRedeem')"
          :closeOnBackdrop="false">
      <CForm>
        <CInput :label="$t('TxnID')"
                v-model="txn_id"
                :description="$t('TxnIDDescription')"/>
      </CForm>
    <template v-slot:footer>
      <button
            type="button"
            class="btn btn-secondary"
            @click="$emit('update:show', false)">
          {{ $t('Cancel') }}
        </button>
        <button
          type="button"
          class="btn btn-primary"
          @click="clickRedeemTransaction()">
          {{ $t('Redeem') }}
        </button>
    </template>
  </CModal>
</template>

<script>
import axios from '@/plugins/axios'

export default {
  name: 'ManualRedeem',
  data() {
    return {
      txn_id: null,
      mutatedShow: false,
    }
  },
  props: ['bill_no', 'show'],
  watch: {
    show() {
      this.mutatedShow = this.show
    },
    mutatedShow() {
      this.$emit('update:show', this.mutatedShow)
    }
  },
  methods: {
    clickRedeemTransaction() {
      axios.post(`plan/transaction/${this.bill_no}/redeem/`, {txn_id: this.txn_id}).then(response => {
        this.mutatedShow = false
        this.$emit('on-redeem', response.data)
      }).catch(({errors}) => {
        errors.forEach(item => {
          this.flash(item, 'warning', 5000)
        })
      })
    }
  }
}
</script>
