<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CCard>
          <CCardHeader>
            <slot name="header">
              <CRow>
                <CCol sm="4">
                  <CIcon name="cil-grid"/>
                  {{ $t('bill.BillList') }}
                </CCol>
                <CCol sm="4">
                  <div class="date-picker-container">
                    <slideout-panel></slideout-panel>
                    <div class="time-range-display">
                      <span @click="showPanel">
                        <CIcon name="cil-clock" style="margin-right: 6px;"/>
                        {{ timeRangeDisplay }}
                      </span>
                    </div>
                  </div>
                </CCol>
                <CCol sm="2">
                  <multiselect v-model="selectedSubscription"
                               :options="subscriptionData.subscriptions"
                               label="name"
                               track-by="id"
                               :custom-label="planWithCname"
                               :loading="subscriptionData.loading"
                               :close-on-select="true"
                               :placeholder="$t('bill.SelectSubscription')">
                    <span slot="noResult">{{ $t('message.subscription_not_found') }}</span>
                  </multiselect>
                </CCol>
                <CCol sm="2"
                      id="search-wrapper">
                  <CInput type="text"
                          :placeholder="$t('Search')"
                          v-model="search"
                          size="sm"
                          id="search-input">
                    <template #append>
                      <CButton type="submit"
                               color="primary"
                               @click="filterBySearch()">
                        {{ $t('Search') }}
                      </CButton>
                    </template>
                  </CInput>
                </CCol>
              </CRow>
            </slot>
          </CCardHeader>
          <CCardBody>
            <CDataTable :hover="false"
                        :striped="false"
                        :bordered="false"
                        :fixed="false"
                        :dark="false"
                        :fields="billListFields"
                        :pagination="false"
                        :items="items"
                        :loading="listLoading"
                        :items-per-page="perpage"
                        :items-per-page-select='{"label": $t("NumberOfItemsPerPage"), "values": [20,50,100]}'
                        @pagination-change="paginationChanged"
                        ref="billingTable">
              <template #bill_no="{item}">
                <td class="bill-no"
                    @click="showBillDetail(item.bill_no)">
                  {{ item.bill_no }}
                </td>
              </template>
              <template #subscription="{item}">
                <td>
                  <template v-if="item.subscription_history && item.subscription_history.subscription">
                    <template v-if="item.subscription_history.plan">
                      {{ item.subscription_history.plan.name }} {{ item.subscription_history.subscription.cname }}
                    </template>  
                    <template v-else>
                      {{ item.subscription_history.subscription.cname }}
                    </template>                      
                  </template>
                </td>
              </template>
              <template #amount_currency1="{item}">
                <td>
                  {{ item.amount_currency1 }}
                </td>
              </template>
              <template #amount="{item}">
                <td>
                  {{ Number(item.amount).toFixed(8) }} {{ item.currency2 }}
                </td>
              </template>
              <template #status="{item}">
                <td>
                  <CBadge :color="getBadgeColor(item)" shape="pill">{{ item.status_display }}</CBadge>
                </td>
              </template>
              <template #created="{item}">
                <td>
                  {{ $moment(item.created).format('DD/MM/YYYY H:mm') }}
                </td>
              </template>
              <template #modified="{item}">
                <td>
                  {{ $moment(item.modified).format('DD/MM/YYYY H:mm') }}
                </td>
              </template>
            </CDataTable>
            <CPagination
                :activePage.sync="page"
                :pages="total_pages"
                align="center"
                @update:activePage="pageChanged"
              />
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <CModal :title="$t('bill.BillDetail')"
            :show.sync="billDetailModal"
            size="lg"
            ref="modalBillDetail"
            v-if="billDetail">
      <CRow v-if="billDetailModal">
        <CCol sm="6">
          <p>
            <b>{{ $t('bill.BillNo') }}</b>
          </p>
          <p>{{ billDetail.bill_no }}</p>
        </CCol>
        <CCol sm="6">
          <p>
            <b>{{ $t('bill.TransactionStatus') }}</b>
          </p>
          <p>
            <CBadge :color="getBadgeColor(billDetail)" shape="pill">{{ billDetail.status_display }}</CBadge>
          </p>
        </CCol>
        <CCol sm="6">
          <p>
            <b>{{ $t('bill.Amount') }} ({{ billDetail.currency1 }})</b>
          </p>
          <p>{{ billDetail.amount_currency1 }}</p>
        </CCol>
        <CCol sm="6">
          <p>
            <b>{{ $t('bill.Amount') }} ({{ billDetail.currency2 }})</b>
          </p>
          <p>{{ billDetail.amount }}</p>
        </CCol>
        <CCol sm="6">
          <p>
            <b>{{ $t('bill.PaymentType') }}</b>
          </p>
          <p>{{ billDetail.payment_type_display }}</p>
        </CCol>
        <CCol sm="6">
          <p>
            <b>{{ $t('bill.TransactionType') }}</b>
          </p>
          <p>{{ billDetail.type_display }}</p>
        </CCol>
        <CCol sm="12"
              v-if="billDetail.type !== Transaction.TRANSACTION_TYPE.TRANSACTION_TYPE_TOPUP && billDetail.subscription_history">
          <p><b>{{ $t('bill.ItemDescription') }}</b></p>
          <CRow>
            <CCol col="6">
              <p><b>{{ $t('bill.CNAME') }}</b></p>
              <p v-if="billDetail.subscription_history.subscription">{{ billDetail.subscription_history.subscription.cname }}</p>
            </CCol>

            <CCol col="6">
              <p><b>{{ $t('bill.PlanName') }}</b></p>
              <p v-if="billDetail.subscription_history.plan">{{ billDetail.subscription_history.plan.name }}</p>
              <p v-else>-</p>
            </CCol>

            <CCol col="6">
              <p><b>{{ $t('bill.AdditionalDomainNumber') }}</b></p>
              <p>{{ billDetail.subscription_history.additional_domain_number }}</p>
            </CCol>

            <CCol col="6">
              <p><b>{{ $t('bill.SubscriptionType') }}</b></p>
              <p>{{ billDetail.subscription_history.type_display }}</p>
            </CCol>

            <CCol col="6">
              <p><b>{{ $t('bill.AdditionalTrafficNumber') }}</b></p>
              <p>{{ billDetail.subscription_history.additional_traffic }}</p>
            </CCol>

          </CRow>
        </CCol>
        <CCol sm="12"
              v-show="billDetail.status === Transaction.TRANSACTION_STATUS.TRANSACTION_STATUS_WAITING_FUND && billDetail.payment_type === Transaction.TRANSACTION_PAYMENT_TYPE.TRANSACTION_PAYMENT_TYPE_CYRPTOCURRENCY">
          <span v-html="$t('bill.TransactionDescription', { amount: billDetail.amount + ' ' + billDetail.currency2, address: billDetail.address })"></span>
        </CCol>
      </CRow>
      <template v-slot:footer>
        <button type="button"
                class="btn btn-secondary"
                @click="$refs.modalBillDetail.hide($event)">
          {{ $t('Close') }}
        </button>
        <button type="button"
                 class="btn btn-primary"
                 v-if="billDetail.status === -100"
                 @click="clickPayment()">
          {{ $t('Pay') }}
        </button>
        <button type="button"
                class="btn btn-primary"
                v-if="billDetail.status === Transaction.TRANSACTION_STATUS.TRANSACTION_STATUS_WAITING_FUND && billDetail.payment_type === Transaction.TRANSACTION_PAYMENT_TYPE.TRANSACTION_PAYMENT_TYPE_CYRPTOCURRENCY"
                @click="clickManualRedeem()">
          {{ $t('ManualRedeem') }}
        </button>
      </template>
    </CModal>
    <manual-redeem :show.sync="showManualRedeem"
                   v-if="billDetail"
                   :bill_no="billDetail.bill_no"
                   @on-redeem="onRedeem"/>
    <CModal :title="$t('checkout.SelectPaymentMethod')"
            :show.sync="showModal"
            :closeOnBackdrop="false"
            v-if="billDetail">
      <input type="radio"
             id="cryptocurrency"
             name="payment_type"
             value="cryptocurrency"
             v-model="selectedPaymentType">
      <label for="cryptocurrency">{{ $t('checkout.Cryptocurrency') }}</label>
      <ul id="coins" :class="{Disabled: selectedPaymentType !== Transaction.TRANSACTION_PAYMENT_TYPE.TRANSACTION_PAYMENT_TYPE_CYRPTOCURRENCY}">
        <li v-for="coin in coinSupports"
            :key="'coin-' + coin.id"
            v-if="coin.is_show"
            @click="clickCoins(coin.code, $event)">
          <div class="box"></div>
          <img :src="coin.icon"/>
          <p>{{ coin.name }}</p>
        </li>
      </ul>
      <input type="radio"
             id="accountbalance"
             name="payment_type"
             value="account_balance"
             v-model="selectedPaymentType">
      <label for="accountbalance">{{ $t('checkout.AccountBalance') }}</label>
      <p>{{ $t('checkout.YourBalance') }}: <b :class="{BalanceDanger: parseFloat($store.state.balance) < parseFloat(billDetail.amount_currency1)}">{{ $store.state.balance }}</b></p>
      <template v-slot:footer>
        <button
            type="button"
            class="btn btn-secondary"
            @click="showModal=false">
          {{ $t('Cancel') }}
        </button>
        <button
          type="button"
          class="btn btn-primary"
          @click="clickPay()">
          {{ $t('Pay') }}
        </button>
      </template>
    </CModal>
  </div>
</template>

<script>
import {
  format,
  subMonths,
  parseISO,
  differenceInMinutes
} from 'date-fns';
import Multiselect from 'vue-multiselect';
import ManualRedeem from './ManualRedeem.vue'

import {ADJUSTMENT_TYPE, TIME_ADJUSTMENTS} from '@/views/domain/charts/TimeRangePicker';

import axios from "@/plugins/axios.js"
import { payPlan } from '@/utilities/api'
import Transaction from '@/utilities/transaction'
import {FETCH_COIN_SUPPORT_IF_EMPTY} from '@/store/types';
import CDataTableCustom from '@/views/domain/CDataTableCustom';
import {EventBus, Events} from "@/plugins/event-bus.js";


export default {
  name: 'TransactionBills',
  components: {
    Multiselect,
    ManualRedeem,
    CDataTableCustom
  },
  data() {
    return {
      items: [],
      search: "",
      listLoading: false,
      queryParams: {},
      page: 1,
      total_rows: 0,
      total_pages: 0,
      perpage: 20,
      billDetailModal: false,
      billDetail: null,
      selectedCoin: null,
      selectedPaymentType: null,
      showModal: false,
      subscriptionData: {
        subscriptions: [],
        loading: false
      },
      selectedSubscription: null,
      showManualRedeem: false,
      Transaction: Transaction
    }
  },
  computed: {
    coinSupports: function() {
      return this.$store.state.coinSupports;
    },
    timeRange: {
      get() {
        return this.$store.state.timeRangePicker.range;
      },
      set(value) {
        this.$store.state.timeRangePicker.range = value;
      }
    },
    timeAdjustment: {
      get() {
        return this.$store.state.timeRangePicker.adjustment;
      },
      set(value) {
        this.$store.state.timeRangePicker.adjustment = value;
      }
    },
    timeRangeDisplay: function () {
      const timeFormat = 'MMM d, yyyy, HH:mm:ss';

      return format(this.timeRange.start, timeFormat) + ' ~ ' + format(this.timeRange.end, timeFormat);
    },
    billListFields() {
      return [
        {
          key: "bill_no",
          label: this.$t('bill.BillNo')
        },
        {
          key: "subscription",
          label: this.$t('bill.Subscription')
        },
        {
          key: "amount_currency1",
          label: this.$t('bill.Amount') + '(USDT)'
        },
        {
          key: "amount",
          label: this.$t('bill.Amount')
        },
        {
          key: "status",
          label: this.$t('bill.Status')
        },
        {
          key: "created",
          label: this.$t('bill.Created')
        },
        {
          key: "modified",
          label: this.$t('bill.Modified')
        }
      ]
    },
    checkoutListFields() {
      return [
        {
          key: 'plan',
          label: this.$t('checkout.PlanName')
        },
        {
          key: 'cname',
          label: this.$t('checkout.CNAME')
        },
        {
          key: 'additional_domain_number',
          label: this.$t('checkout.AdditionalDomainNumber')
        },
        // {
          // key: 'additional_traffic',
          // label: this.$t('checkout.AdditionalTraffic')
        // },
        {
          key: 'amount_price',
          label: this.$t('checkout.AmountPrice')
        },
        {
          key: 'type_display',
          label: this.$t('checkout.Type')
        }
      ]
    }
  },
  created() {
    this.fetchSubscriptions()
    this.fetchData()
    this.$store.dispatch(FETCH_COIN_SUPPORT_IF_EMPTY);
  },
  mounted() {
    this.$store.state.timeRangePicker.range = {start: subMonths(new Date(), 1),end: new Date()};
    this.$store.state.timeRangePicker.adjustment = [ADJUSTMENT_TYPE.RELATIVE, TIME_ADJUSTMENTS.THIS_MONTH];
    this.onApplyTimeRange();
  },
  beforeDestroy() {
    EventBus.$off(Events.DOWNLOAD_CERTIFICATE);
  },
  methods: {
    onRedeem(transaction) {
      let trans = this.items.find(t => d.bill_no === transaction.bill_no)
      trans = transaction
      this.$refs.billingTable.$forceUpdate()
    },
    fetchSubscriptions() {
      this.subscriptionData.loading = true
      axios.get('plan/subscription').then(response => {
        this.subscriptionData.subscriptions = response.data
        this.subscriptionData.loading = false
      }).catch((error) => {
        console.log(error);
      })
    },
    clickManualRedeem() {
      this.showManualRedeem = true
      this.billDetailModal = false
    },
    clickPayment() {
      this.showModal = true
      this.billDetailModal = false
    },
    clickCoins(coin, $event) {
      if (this.selectedPaymentType === 'cryptocurrency') {
        const li = document.querySelectorAll('ul#coins li')
        li.forEach(item => {
          item.classList.remove('Active')
        })
        const $target = $event.target
        $target.parentNode.classList.toggle('Active')
        this.selectedCoin = coin
      }
    },
    clickPay() {
      if (this.selectedPaymentType) {
        if (this.selectedPaymentType === 'cryptocurrency') {
          if (!this.selectedCoin) {
            this.flash(this.$t('checkout.WarningCoin'), 'warning')
            return
          }
        }
        if (this.selectedPaymentType === 'account_balance') {
          if (parseFloat(this.$store.state.balance) < parseFloat(this.$store.state.transaction.checkout.amount_currency1)) {
            this.flash(this.$t('checkout.WarningBalance'), 'warning')
            return
          }
          this.selectedCoin = 'USDT'
        }

        payPlan(this.billDetail.bill_no, this.selectedCoin, this.selectedPaymentType).then(response => {
          this.showModal = false
          this.flash(this.$t('checkout.SuccessPaymentType'), 'success', {timeout: 4000})
          this.$store.dispatch('fetchCheckout')
          if (this.selectedPaymentType === 'cryptocurrency') {
            window.open(response.data.checkout_url, 'Checkout').focus()
          }
          if (this.selectedPaymentType === 'account_balance') {
            axios.get('plan/balance/').then(response => {
              this.$store.state.balance = response.data.amount
            }).catch(error => console.log(error))
          }
          this.$router.push({name: 'DomainList'})
        }).catch(error => console.log(error))
      } else {
        this.flash(this.$t('checkout.WarningPaymentType'), 'warning')
      }
    },
    showBillDetail(billNo) {
      this.billDetailModal = true
      axios.get(`plan/transaction/${billNo}/`).then(response => {
        this.billDetail = response.data
      }).catch(error => {
        console.log(error)
      })
    },
    pageChanged(page) {
      this.page = page
      this.fetchData()
    },
    paginationChanged(itemsPerPage) {
      this.perpage = itemsPerPage
      this.fetchData()
    },
    filterBySearch() {
      this.page = 1;
      this.fetchData();
    },
    fetchData() {
      this.listLoading = true;
      let filterParams = {
        page: this.page,
        perpage: this.perpage
      }
      if(this.search) {
        filterParams['search'] = this.search
      }
      if (this.selectedSubscription) {
        filterParams['subscription'] = this.selectedSubscription.id
      }

      filterParams['start'] = format(this.timeRange.start, 'yyyy-MM-dd HH:mm:ss xx')
      filterParams['end'] = format(this.timeRange.end, 'yyyy-MM-dd HH:mm:ss xx')

      return axios
        .get('plan/transaction/', {
          params: filterParams,
        })
        .then(response => {
          this.items = response.data.results
          this.total_rows = response.data.count
          this.total_pages = Math.ceil( this.total_rows / this.perpage )
          this.listLoading = false

        })
        .catch(error => {
          this.listLoading = false
          console.log(error)
        })
        .finally(() => {
          this.listLoading = false
        });
    },
    async onApplyTimeRange() {
      this.timeRangePanel && this.timeRangePanel.hide();
      this.fetchData();
    },
    showPanel() {
      this.timeRangePanel = this.$showPanel({
        component: 'app-time-range-picker',
        openOn: 'left',
        cssClass: 'timerange-slideout',
        width: 400,
        props: {
          onApply: this.onApplyTimeRange,
        }
      });
    },
    planWithCname({plan, cname}) {
      return `${plan.name} ${cname}`
    },
    getBadgeColor(billDetail) {
      return billDetail.status === this.Transaction.TRANSACTION_STATUS.TRANSACTION_STATUS_WAITING_FUND ? 'secondary' :  billDetail.status < this.Transaction.TRANSACTION_STATUS.TRANSACTION_STATUS_WAITING_FUND ? 'danger' : billDetail.status > this.Transaction.TRANSACTION_STATUS.TRANSACTION_STATUS_WAITING_FUND && billDetail.status < this.Transaction.TRANSACTION_STATUS.TRANSACTION_STATUS_PAYMENT_SUCCESS ? 'warning' : 'success'
    }
  },
}
</script>

<style scoped>
.bill-no {
  cursor: pointer;
  color: blue;
}

ul#coins {
  padding: 0;
  list-style: none;
}

ul#coins.Disabled li {
  border: unset !important;
  background-color: #eee;
}

ul#coins li {
  position: relative;
  display: inline-block;
  padding: 10px;
  margin: 10px;
  border: 1px solid #eee;
  border-radius: 10px;
  cursor: pointer;
}

ul#coins li.Active {
  border: 1px solid #0064bd;
}

ul#coins li div.box {
  position: absolute;
  top: 0;
  width: 100px;
  height: 100px;
}

ul#coins li img {
  width: 100px;
  height: 100px;
}

ul#coins p {
  margin: 0;
  text-align: center;
}
.BalanceDanger {
  color: #e55353;
}

.date-picker-container {
  float: right;
}
.time-range-label {
  margin-bottom: 5px;
}

.time-range-display span {
  display: inline-flex;
  align-items: center;
  color: #321fdb;
  cursor: pointer;
  font-weight: 500;
  padding: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
}

.time-range-display span:hover {
  background-color: #fbfbfb;
}

#search-wrapper .form-group {
  margin-bottom: 0;
}
</style>

<style>
#search-input {
  height: calc(1.5em + 0.5rem + 2px + 1em);
}
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
